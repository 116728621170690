@import "src/styles/palette";

.app-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main;
  font-family: HelveticaNeue;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.235;
  color: $font;

  .app-loading-root {
    position: relative;

    .loading-animation {
      position: relative;
      top: 10px;
      width: 80px;
      height: 80px;
      left: 50%;
      transform: translateX(-50%);
    }

    .loading-animation:after {
      content: " ";
      display: block;
      width: 50px;
      height: 50px;
      margin: 8px;
      border-radius: 50%;
      border: 3px solid $font;
      border-color: $font transparent $font transparent;
      animation: loading-animation 1.2s linear infinite;
    }
  }
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
  position: absolute;
  top: -70px;
  left: 60px;
}

.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.loader:after {
  color: $ocean;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

.loader:before {
  color: $violet;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
