$font: #87888c;

$main: #171821;
$secondary: #21222d;
$secondary02: rgba(33, 34, 45, 0.2);
$tertiary: #13161d;
$layoutContentBG: #f5f5f5;
$transparent: #00000000;

$background: #f0f2f5;

$border: rgba(5, 5, 5, 0.06);

$white: #ffffff;
$white05: rgba(255, 255, 255, 0.5);
$white03: rgba(255, 255, 255, 0.3);
$white02: rgba(255, 255, 255, 0.2);
$white01: rgba(255, 255, 255, 0.1);

$black: #000;
$black088: rgba(0, 0, 0, 0.88);
$black05: rgba(0, 0, 0, 0.5);
$black02: rgba(0, 0, 0, 0.2);
$black01: rgba(0, 0, 0, 0.1);

$grey: #edeff3;
$greyMiddle: rgb(128, 128, 128);
$greyMiddle01: rgba(128, 128, 128, 0.1);
$greyDark: #bfc1c4;
$greySoft: rgba(0, 0, 0, 0.06);

$blue: #28aef3;
$blue05: rgba(40, 175, 243, 0.5);
$softBlue: #5393ff;
$softBlue02: rgba(83, 146, 255, 0.2);
$softBlue01: rgba(83, 146, 255, 0.1);
$darkBlue: #155575;
$darkBlueBackground: rgb(0, 0, 40, 0.02);
$blueLink: #1677ff;

$ocean: #a9dfd8;
$ocean03: rgba(169, 223, 216, 0.3);
$ocean06: rgba(169, 223, 216, 0.6);

$red: #ff0000;
$red01: rgb(255, 0, 0, 0.1);
$softRed: rgb(218, 90, 90);
$errorRed: #f14747;

$orange: #feb95a;
$orange05: rgba(254, 186, 90, 0.5);
$orange02: rgba(224, 152, 50, 0.2);
$softOrange: #dda75c;
$darkOrange: rgb(168, 113, 50);

$yellow: rgb(244, 196, 48);

$violet: #f2c8ed;
$violet02: rgba(173, 63, 201, 0.2);
$softViolet: rgb(195, 100, 197);
$darkViolet: rgb(130, 43, 173);

$green: green;
$softGreen: #33eb91;

$highlighted: #fef1e1;
