@import "./palette.scss";
@import "./variables.scss";
@import "./mixins.scss";
@import "./loader.scss";

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  font-family: HelveticaNeue, Arial, sans-serif;
  letter-spacing: 0.3px;
  -webkit-font-smoothing: antialiased;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $main;
  padding: 0;
  margin: 0;
  height: 100vh;

  &.no-scroll {
    overflow-y: hidden;
  }
}

.link {
  @include underlineLink;
}

.nowrap {
  white-space: nowrap;
}

.asc {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid $main;
    transform: translateY(-50%);
    top: 50%;
    left: 4px;
  }
}

.desc {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #292d33;
    transform: translateY(-50%);
    top: 50%;
    left: 4px;
  }
}

.sortingMoreLeft {
  &:before {
    left: -15px;
  }
}
