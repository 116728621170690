@import "src/styles/palette";

@mixin from($w) {
  @media screen and (min-width: #{$w}px) {
    @content;
  }
}

@function spacing($number) {
  $result: ($number * 0.5) + rem;

  @return $result;
}

@mixin underlineLink {
  text-decoration: underline;
  color: inherit;
  cursor: pointer;
}

@mixin tableCreateButton {
  width: 100px;
  justify-self: right;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $color2;
    border-radius: 0 0 4px 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $rgbaColor5;
    border-radius: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: $rgbaColor5 $rgbaColor6;
}
